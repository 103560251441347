import React from 'react';
import { MdOutlineSavings, MdAdsClick } from 'react-icons/md';
import { BsShieldCheck } from 'react-icons/bs';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';

const features = [
  {
    name: 'Économisez des commissions en affiliation',
    description:
      'Stoppez les campagnes de vos partenaires et affiliés qui ne respectent pas vos conditions.',
    icon: MdOutlineSavings,
  },
  {
    name: 'Supprimez la concurrence inutile autour de votre marque',
    description:
      'Limitez les annonces qui utilisent votre marque et ne les laissez pas augmenter vos CPC.',
    icon: BsShieldCheck,
  },
  {
    name: 'Protégez votre image de marque',
    description:
      "Le trafic marque est l'un des plus performant. Evitez à vos internautes d'arriver sur d'autres sites qui pourraient nuire à votre image de marque.",
    icon: MdAdsClick,
  },
  {
    name: 'Analysez les augmentations de CPC',
    description:
      'Identifiez simplement pourquoi vos campagnes marques augmentent subitement.',
    icon: AiOutlineCopyrightCircle,
  },
];

export default function Example() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Des bénéfices immédiats
            </h2>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-700 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
